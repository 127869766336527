.BDMDashBoardLine button {
    padding: 2px 5px;
}

.menuItemTasks,
.tasksRelated {
    display: none;
}

.historicSalesRelated {
    display: none;
}

.registrationRelated {
    display: none;
}

.membershipRelated {
    display: none;
}

.CompanyAnnouncements {
    display: none;
}

.EBSDashboard {
    display: none;
}

.controlisModerator,
.controlsalesContact,
.controlcontentContact {
    display: none;
}

.chIcashPaid {
    display: block;
}

.salesProgramSplitRelated {
    display: none;
}