@import "_config.scss";

div.notification div.bottom h4 {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
}

div.notification div.bottom {
    font-size: 12px;
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 50000;

    background: #eee;
    color: #000;
    
  }

div.notification div.bottom.error h4 {
    color: #fff;
} 

div.notification div.bottom.error {
    background: rgb(255, 123, 123);
    color: #ccc;
    
}
